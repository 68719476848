import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { DateTime } from 'luxon';

const api = new WooCommerceRestApi({
  url: 'https://roelandsplant.ca',
  consumerKey: 'ck_e5430fa91aa5cf5576e2916c954240bd0e1427a6',
  consumerSecret: 'cs_695ccb0e80be29b608bfc56e182c18f952f8eb67',
  version: 'wc/v3',
});

/**
 * 
 */
export default () => {
  return {
    /**
     * Key Local Storage
     */
    keyLocalStorage: '',

    /**
     * Terms
     */
    terms: false,

    /**
     * Products
     */
    products: false,

    /**
     * Min Order
     */
    minOrder: false,

    /**
     * Bag
     */
    bag: { },
    
    /**
     * Tab
     */
    tab: false,

    /**
     * Allowed Weeks
     */
    allowedDeliveryWeeks: { },

    /**
     * Tab Focus
     * 
     * @param {object} $event
     */
    tabFocus() {
      const node = this.$refs.focus;
      const bounds = this.$refs[this.tab.slug].getBoundingClientRect();
      const boundsTabs = this.$refs.tabs.getBoundingClientRect();
      node.style.transform = `translateX(${bounds.left - boundsTabs.left}px)`;
      node.style.width = `${bounds.width}px`;
      node.style.opacity = '1';
    },

    /**
     * Set Local Storage
     * 
     * @description
     */
    initLocalStorage(locked) {
      if (localStorage.getItem(this.keyLocalStorage) && !locked) {
        this.bag = JSON.parse(localStorage.getItem(this.keyLocalStorage));
      }

      /**
       * Query
       * 
       * @param {*} q 
       */
      const getQuery = (q) => {
        return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
      }

      if (getQuery('bag')) {
        const query = decodeURIComponent(getQuery('bag')).split(',');

        const queryFormatted = query.reduce((result, item) => {
          item = item.split(':');
          result[item[0]] = item[1];
          return result;
        }, { });

        this.bag = queryFormatted;
      }
    },

    /**
     * Get Terms
     * 
     * @param {*} term 
     */
    getTerms(term) {
      api.get(`products/categories`).then((res) => {
        const terms = res.data.reduce((arr, item) => {
          if (item.parent === term && item.count > 0) {
            arr.push(item);
          }
          return arr;
        }, []);

        this.terms = terms;
        /**
         * Set the first tab
         */
        this.tab = this.terms[0];
        this.$nextTick(() => {this.tabFocus()});
      })
      .catch((error) => {
        console.log(error);
      });
    },

    /**
     * Get Term Products
     * 
     * @param {string} term
     */
    getProducts(term) {
      api.get('products', {
        'category': term,
        'per_page': 100,
        'stock_status': 'instock',
      })
      .then((res) => {
        /**
         * Convert to object with ID as keys
         * 
         *  [
         *    id: {
         *      ...data
         *    }
         *  ]
         */              
        const products = res.data.reduce((result, item) => {
          /**
           * Stock
           */
          if (item.stock_status === 'instock') {
            result[item.id] = item;
          }

          return result;
        }, { });

        /**
         * 
         */
        if (Object.entries(products).length !== 0) {
          /**
           * 
           */
          if (this.products === false) {
            this.products = { };
          }

          /**
           * 
           */
          this.products = {...this.products, ...products};
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },

    /**
     * 
     * @param {*} term 
     */
    filterTermProducts(term) {
      /**
       * 
       */  
      const matches = Object.values(this.products).reduce((result, item) => {
        if (item.categories.filter(i => (i.id === term.id)).length) {
          result.push(item);
        }
        return result;
      }, [ ]);

      /**
       * Sorting alphabetically.
       * 
       * @link https://stackoverflow.com/questions/6712034/sort-array-by-firstname-alphabetically-in-javascript
       */
      const matchesOrderAlpha = matches.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      return matchesOrderAlpha;
    },

    /**
     * Pay
     * 
     * @description
     */
    redirectToPay(url) {
      const items = Object.keys(this.bag).join(',');
      const quantities = Object.values(this.bag).join(',');

      window.location.href = `${url}?type=${this.keyLocalStorage}&add-to-cart=${items}&quantities=${quantities}`;
    },

    /**
     * Save Local Storage
     * 
     * @description
     */
    saveLocalStorage() {
      localStorage.setItem(this.keyLocalStorage, JSON.stringify(this.bag));
    },

    /**
     * Add
     * 
     * @description add item to `this.bag`
     */
    add($event, item) {
      let value = parseInt($event.target.value);
      // allow doubles for weekly values
      /*
      if (this.keyLocalStorage === 'weekly') {
        value = parseFloat($event.target.value);
      }
      */

      /** 
       * Basic validation
       */
      if (
        value < 0 || 
        value === 0 ||
        value === null ||
        value === '' ||
        isNaN(value)) {
        delete this.bag[item.id];
        return;
      }

      /**
       * Enough stock
       */
      if (item.stock_quantity && (value * item.items_per_tray) > item.stock_quantity) {
        value =  item.stock_quantity / item.items_per_tray;
      }

      /**
       * Set
       */
      this.bag[item.id] = value * item.items_per_tray;
    },

    /**
     * Remove
     * 
     * @description remove item from `this.bag`
     * 
     * @link https://stackoverflow.com/questions/16491758/remove-objects-from-array-by-object-property
     */
    /*
    remove(id) {
      if (this.bag[id] === 1) {
        delete this.bag[id]; 
      } else {
        this.bag[id] = this.bag[id] - 1;
      }

      // remove
      this.bag.splice(this.bag.findIndex((item) => {
        return item.ID === id;
      }), 1);
    },
    */

    /**
     * Get Bag Price
     * 
     * @description
     * 
     * @link
     */
    getBagPrice() {
      const prices = Object.keys(this.bag).reduce((result, id) => {
        /**
         * Use dataset `this.products` to get price and multiply with `this.bag[id]` which contains the quantity
         */
        if (this.products[id]) {
          result[id] = this.products[id].price * this.roundNum(this.bag[id]);
        }

        return result;
      }, { });

      const total = this.sumOfArr(Object.values(prices));

      return this.numToCurrency(total);
    },

    /**
     * Get Bag Trays
     */
    getBagPacking() {
      /**
       * 
       */
      const reducedUnits = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          result[id] = this.roundNum(this.bag[id]);
        }
        return result;
      }, { });

      /**
       * 
       */
      const reducedTrays = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          result[id] = this.roundNum(this.bag[id]) / this.products[id].items_per_tray;
        }
        return result;
      }, { });

      /**
       * 
       */
      const reducedCarts = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          const trays = this.bag[id] / this.products[id].items_per_tray;
          const carts = trays / this.products[id].trays_per_cart;

          result[id] = Math.ceil(carts * 1000) / 1000;
        }
        return result;
      }, { });


      /**
       * 
       */
      const units = this.sumOfArr(Object.values(reducedUnits));
      const trays = this.sumOfArr(Object.values(reducedTrays));
      const carts = this.sumOfArr(Object.values(reducedCarts));

      console.log({units, trays, carts});
      // const carts = this.roundNum(trays / 45);

      return {
        units,
        trays,
        carts,
      }
    },

    /**
     * Get Bag List
     * 
     * @description
     * 
     * @link https://stackoverflow.com/questions/19395257/how-to-count-duplicate-value-in-an-array-in-javascript
     */
    getBagList() {
      /**
       * List
       */
      const list = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          result.push({
            id,
            title: this.products[id].name,
            price: this.numToCurrency(this.products[id].price),
            quantity: this.bag[id],
            itemsPerTray: this.products[id].items_per_tray,
          });
        }

        return result;
      }, [ ]);

      /**
       * Sort List
       * 
       * Alphabetical order
       * 
       * @link https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
       */
      const sortList = list.sort((a, b) => {
        return b.title > a.title;
      });

      // return array with values for alpinejs `<template>`
      return sortList;
    },

    /**
     * Get Longest Lead Time
     * 
     * @description
     * 
     * @link 
     */
    getDelivery() {
      /**
       * Get highest `lead_time` value
       */
      const time = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          return (result > this.products[id].lead_time) ? result : this.products[id].lead_time;
        }
      }, 0);

      /**
       * Get all items with same max `lead_time`
       */
      const namesArr = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          if (this.products[id].lead_time === time) {
            result.push(this.products[id].name)
          }
        }

        return result;
      }, []);

      const names = (namesArr.length > 3) ? 
        namesArr.slice(0, 2).join(', ') + ' and ' + (namesArr.length - 2) + ' other products' : 
        namesArr.join(', ');

      let ships = DateTime
        .local()
        .plus({ weeks: time })
        .startOf('week')
        .plus({ weeks: 1 });

      /**
       * Integrate with ACF `allowed_weeks`
       */
      if (this.allowedDeliveryWeeks) {
        const allowedFromLeadTime = this.allowedDeliveryWeeks.reduce((result, entry) => {
          if (entry.allowed_week > ships.toFormat('yyyyLLdd')) {
            result.push(entry.allowed_week);
          }
          return result;
        }, [ ]);

        // check if first item exists, then change to first array item from `allowFromLeadTime`
        if (typeof allowedFromLeadTime[0] !== 'undefined') {
          ships = DateTime.fromFormat(allowedFromLeadTime[0], 'yyyyLLdd');
        }
      }

      return {
        time,
        names,
        ships: ships.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
      }
    },

    /**
     * Get Shipping
     * 
     * @description
     * 
     * @link 
     */
    getFreeShipping() {
      /**
       * Get highest `lead_time` value
       */
      const time = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          return (result > this.products[id].lead_time) ? result : this.products[id].lead_time;
        }
      }, 0);

      /**
       * Get all items with same max `lead_time`
       */
      const namesArr = Object.keys(this.bag).reduce((result, id) => {
        if (this.products[id]) {
          if (this.products[id].lead_time === time) {
            result.push(this.products[id].name)
          }
        }

        return result;
      }, []);

      const names = namesArr.join(', ');

      /*
      const ships = () => {
        const currentTime = new Date();
        currentTime.setDate(currentTime.getDate() + 14);
        return currentTime;
      }
      */

      return {
        time,
        names,
        // ships,
      }
    },

    /**
     * Sum Of Array
     * 
     * @param {array} arr 
     */
    sumOfArr(arr) {
      return arr.reduce((a, b) => a + b, 0);
    },

    /**
     * Number to Currency
     * 
     * Create new Intl number format
     * 
     * @param value
     */
    numToCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        currency: 'USD',
        style: 'currency',
      }).format(value);
    },

    /**
     * Round Number
     * 
     * Round float to two decimal points
     * 
     * @param value
     */
    roundNum(num) {
      return Math.round(num * 100) / 100
    },
  }
}
