import 'alpinejs';
import store from './partials/store';
import legacy from './legacy';
import googleMaps from './google-maps';

/**
 * Legacy code from Sage 8
 */
$(document).ready(() => {
  legacy();
  googleMaps();
});

/**
 * Alpine Javascript for new store
 */
window.App = {
  store,
  /* alpinejs comps */
}
