/* eslint-disable */
export default () => {
  /*jshint latedef: nofunc */

  function new_map($el) {
    var $markers = $el.find('.marker');
    var args = {
      zoom: 4,
      center: new google.maps.LatLng(0, 0),
      scrollwheel: false,
      draggable: false,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    var map = new google.maps.Map($el[0], args);

    map.markers = [];

    $markers.each(function () {
      add_marker($(this), map);
    });

    center_map(map);

    return map;
  }

  function add_marker($marker, map) {
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    var marker = new google.maps.Marker({
      position: latlng,
      map: map
    });

    map.markers.push(marker);

    if ($marker.html()) {
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });
    }
  }

  function center_map(map) {
    var bounds = new google.maps.LatLngBounds();

    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(latlng);
    });

    if (map.markers.length === 1) {
      map.setCenter(bounds.getCenter());
      map.setZoom(4);
    } else {
      map.fitBounds(bounds);
    }
  }

  var map = null;
  var maps = $('.acf-map');
  $(document).ready(function () {
    maps.each(function () {
      map = new_map($(this));

      // Usability
      // http://bdadam.com/blog/simple-usability-trick-for-google-maps.html
      function enableScrollingWithMouseWheel() {
        map.setOptions({ scrollwheel: true });
      }

      function disableScrollingWithMouseWheel() {
        map.setOptions({ scrollwheel: false });
      }

      function init() {
        google.maps.event.addListener(map, 'mousedown', function(){
          enableScrollingWithMouseWheel();
        });
      }

      google.maps.event.addDomListener(window, 'load', init);

      $('body').on('mousedown', function(event) {
        var clickedInsideMap = $(event.target).parents('.acf-map').length > 0;

        if(!clickedInsideMap) {
          disableScrollingWithMouseWheel();
        }
      });

      $(window).scroll(function() {
        disableScrollingWithMouseWheel();
      });

    });
  });
}
