/* eslint-disable */
import 'jquery';
import masonry from 'masonry-layout';
import smoothScroll from 'jquery-smooth-scroll';

export default () => {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('a[href*="#"]:not([data-toggle="collapse"])').smoothScroll();
      },
    },

    'woocommerce_order_received': {
      init: function() {
        /**
         * Query
         * 
         * @param {*} q 
         */
        const getQuery = (q) => {
          return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
        }

        if (getQuery('type')) {
          const type = getQuery('type');
          localStorage.setItem(type, JSON.stringify({ }));
        }
      },
    },

    'social_feed': {
      finalize: function() {
        $(window).load(function() {
          $('.social-masonry').masonry({
            itemSelector: '.social-post',
            gutter: 20
          });
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
}
